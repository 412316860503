<template>
    <div v-bind="$attrs" class="text-sm">
        <div
            class="flex items-center text-gray-700 transition-colors"
            :class="{
                'hover:text-cyan-700 cursor-pointer [&_label]:cursor-pointer': !disabled,
                'opacity-60 cursor-not-allowed [&_label]:cursor-not-allowed': disabled,
            }"
            @click="updateValue"
        >
            <div ref="element" class="bg-white border-2 border-slate-300 rounded size-5 flex select-none shrink-0">
                <Icon v-if="modelValue" name="check" :weight="700" :size="16" :optical-size="20" class="m-auto" />
            </div>

            <label v-if="label || $slots.label" class="pl-2 select-none" :class="labelClass">
                <slot name="label">{{ label }}</slot>
                <span v-if="required" class="text-rose-600">*</span>
            </label>
        </div>

        <div v-if="messages.length" class="text-xs mt-2 text-rose-600">
            {{ messages[0] }}
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        default: '',
    },

    required: {
        type: Boolean,
        default: false,
    },

    modelValue: {
        type: [Boolean, null, undefined],
        required: true,
        default: false,
    },

    labelClass: {
        type: String,
        default: '',
    },

    disabled: {
        type: Boolean,
        default: false,
    },
});

const element = ref(null);
const messages = ref([]);

onMounted(() => {
    const fields = inject('fields', false);

    if (fields) {
        fields.push({
            name: props.name,
            modelValue: computed(() => props.modelValue),
            messages: messages.value,
            element: element.value,
        });
    }
});

function updateValue() {
    if (props.disabled) {
        return;
    }

    messages.value = [];
    emit('change', !props.modelValue);
    emit('update:modelValue', !props.modelValue);
}

defineExpose({
    messages,
    element,
});
</script>
